<template>
	<div class="HomeMobile">

		<div class="HomeMobile--separator">
			<div class="HomeMobile--separator--left"></div>
		</div>

		<div class="HomeMobile--initscreen" @click="removeInitscreen()" :style="{'z-index': initscreenIsActive ? '100' : '-1' }">
			<InitScreen 
			:content="page.colors" 
			:initscreenIsActive="initscreenIsActive"
			context="mobile"></InitScreen>
		</div>

		<div class="HomeMobile--info" v-if="infoMainMobileDisplay">

			<InfoMainMobile 
			:content="page.info" 
			:infoMainDisplay="infoMainDisplay"
			@set-maininfo="setMainInfo">
			</InfoMainMobile>
			
		</div>

		<div class="HomeMobile--slider">

			<SliderMain 
			:content="page.slider_mobile" 
			:initscreenIsActive="initscreenIsActive"
			@set-maininfo="setMainInfo"
			context="mobile">
			</SliderMain>

		</div>


	</div>
</template>

<script>
import clientConfigs from '@/mixins/clientConfigs.js'
import InitScreen from '@/components/InitScreen.vue'
import InfoMainMobile from '@/components/InfoMainMobile.vue'
import SliderMain from '@/components/SliderMain.vue'



export default {
  name: 'HomeMobile',
  mixins: [clientConfigs],
	components: {
		InitScreen,
		InfoMainMobile,
		SliderMain
	},
  data: function(){
    return {
      state : this.$store.state,
      initscreenIsActive: true,
      infoMainDisplay: [false, false],
      infoMainMobileDisplay: false
    }
  },
  watch: {},
	computed: {
		page: function () { return this.$store.state},
	},
  methods: {
		setMainInfo: function(index){

			if (index == 'mobile'){

				// this.$set(this.infoMainDisplay, 0, false)
				// this.$set(this.infoMainDisplay, 0, false)

				this.infoMainMobileDisplay = !this.infoMainMobileDisplay

			}else {


				this.infoMainDisplay.forEach((e, i) => {
					if( i == index ){
						this.$set(this.infoMainDisplay, i, !this.infoMainDisplay[index])

					}else {
						this.$set(this.infoMainDisplay, i, false)
					}
				})

			}
		},

		removeInitscreen: function(){
			this.initscreenIsActive = false
		},
  },

	created(){
		// console.log(this.$store)
		// console.log(this.$client)

	},
	mounted(){
		// setTimeout(() => { this.removeInitscreen() }, 1000);
	}
}
</script>

<style lang="scss">

.HomeMobile{
	position: absolute;
	height: 100%;
	width: 100%;

	&--separator{
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 500;
		pointer-events: none;

		&--left{
			height: calc(50% - 3px);
			width: 100%;
			border-bottom: 3px solid black;
			pointer-events: none;
		}
	}

	&--initscreen{
		position: absolute;
		width: 100%;
		height: 100%;

		// z-index: 100;
	}

	&--slider{
		// position: absolute;
		width: 100%;
		height: 100%;
	}

	&--info{
		position: absolute;
		width: 100%;
		height: 100%;

		z-index: 1000;
	}

}

</style>
