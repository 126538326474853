<template>

  <div class="InfoMainMobile">

    <div class="InfoMainMobile--container">

      <div class="InfoMainMobile--container--title" >
        <div class="InfoMainMobile--container--title--left">
          <div class="InfoMainMobile--container--title--left--title cursor-pointer" @click="setMainInfo('mobile')">Krier</div>
          <div class="InfoMainMobile--container--title--left--contact">

            <a href="mailto:info@krier-gatard.com">
            info@<br>
            krier-gatard<br>
            .com
            </a>

          </div>
        </div>
        <div class="InfoMainMobile--container--title--right" ref="titleRight">
          <div class="InfoMainMobile--container--title--right--title cursor-pointer" @click="setMainInfo('mobile')">Gatard</div>
        </div>
      </div>

      <div class="InfoMainMobile--container--left" ref="contentLeft">

      </div>

      <div class="InfoMainMobile--container--right" ref="contentRight">

        <div class="InfoMainMobile--container--right--main">

          <div class="InfoMainMobile--container--right--main--description">
            <div class="InfoMainMobile--container--right--main--description--fr" v-html="content.description_1"></div>
            <div class="InfoMainMobile--container--right--main--description--en" v-html="content.description_2"></div>
          </div>

          <div class="InfoMainMobile--container--right--main--credits" v-html="content.credits"> </div>

        </div>

      </div>

    </div>

  </div>

</template>

<script>
// import InfoCase from '@/components/infomain/InfoCase.vue'

export default {
  name: 'InfoMainMobile',
  props: ['content', 'context', 'infoMainDisplay'],
  data: function(){
    return {
      windowHeight: ''
    }
  },
  components: {
    // InfoCase
  },
  computed:{


  },
  methods: {

    setMainInfo: function(index){
      // console.log('setMainInfo ', index)
      this.$emit('set-maininfo', index)
    },

    setInitAnimation: function(){

      // 1.
      //    G qui monte
      //    opacité contentRight
      //    
      // 2. contentRight qui monte

      this.$refs.titleRight
      this.$refs.contentLeft
      this.$refs.contentRight

      // this.$refs.titleRight.style.top = '25vh'

      // setTimeout(() => { 
      //   this.$refs.titleRight.style.top = '25vw'
      //   this.$refs.contentRight.style.opacity = 1

      //   setTimeout(() => { 
      //     this.$refs.contentLeft.style.height = '36vh'
      //     this.$refs.contentRight.style.height = '64vh'
      //   }, 500);

      // }, 500);


      // 1. 



      setTimeout(() => { 
        this.$refs.contentLeft.style.height = this.windowHeight / 2 + 125 + 'px'
        this.$refs.contentRight.style.height = this.windowHeight / 2 - 125 +  'px'

        setTimeout(() => { 
          this.$refs.contentRight.style.opacity = 1

          setTimeout(() => { 
            this.$refs.titleRight.style.top = 100 + 'px'
            this.$refs.contentLeft.style.height = 218 + 'px'
            this.$refs.contentRight.style.height = this.windowHeight - 218 + 'px'

          }, 200);

        }, 300);

      }, 250);

    },

    setDestroyAnimation: function(){

    }

  },
  created(){
    // console.log(this.content)
  },
  mounted(){
    this.setInitAnimation()

    let elHeight = this.$el.getBoundingClientRect().height

    this.windowHeight = elHeight

    // console.log(window.screen.height, window.innerHeight, elHeight)
  }
  
}
</script>

<style lang="scss">
@import '@/css/variables';

.InfoMainMobile{
  position : absolute;
  width: 100%;
  height: 100%;

  background-color: white;

  pointer-events: auto;

  z-index: 200;

  &--container{
    display: flex;
    height: 100%;
    flex-direction: column;

    &--title{
      position: absolute;
      height: 100%;

      pointer-events: none;

      &--left{
        margin-top: var(--home-esp-big);
        margin-left: var(--home-esp-big);
        font-size: var(--home-font-title);
        line-height: 0.8;

        display: flex;

        z-index: 1000;
        pointer-events: auto;

        &--contact{
          font-size: var(--info-font-adress);
          margin-left: 20px;
          line-height: 1.2;

          & p {
            padding-bottom: 0.5vh;

          }
        }
      }

      &--right{
        position: absolute;
        margin-top: var(--home-esp-big);
        margin-left: var(--home-esp-big);
        font-size: var(--home-font-title);
        line-height: 0.8;

        transition: all 0.3s ease;
        top: 50%; // ANIMATION

        left: 0;

        pointer-events: auto;

        

        &.isInit{
          top: 25vw;
        }
      }
    }

    &--left{
      width: 100%;


      // background-color: yellow;
      transition: all 0.3s ease;

      height: 50%;  // ANIMATION

      height: calc(50% - 3px); // ANIMATION
      border-bottom: 3px solid black;
    }

    &--right{
      width: 100%;
      overflow: scroll;
      font-size: var(--info-font-description);


      transition: all 0.3s ease;
      height: 50%; // ANIMATION

      opacity: 0;
      line-height: 1.1;



      &--main{
        padding: var(--home-esp-big);


        &--description{

          &--fr{

            & p{
              padding-bottom: var(--home-esp-med);
            }

          }

          &--en{
            font-family: 'ital';

            padding-top: var(--home-esp-big);
            & p{
              padding-bottom: var(--home-esp-med);
            }
          }

        }

        &--credits{
          font-size: var(--info-font-credits);
          padding-top: var(--home-esp-big);


          column-count: 2;
          line-height: 1.15;
          // padding-bottom: var(--home-esp-big);


          & strong{
            text-decoration: underline;
          }

          & p{

            padding-bottom: var(--home-esp-med);

            & a {
              font-family: 'ital';
            }
          }
          
        }

      }
    }
  }
}

.cursor-pointer{
  cursor: pointer;
}

</style>